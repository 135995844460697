import React, { FC } from 'react';
import styled from 'styled-components';
import { menus } from 'constants/index';
import { useSearchParams, Link } from 'react-router-dom';

export const Tab: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab');

  return (
    <TabContainer>
      {menus.map(menu => (
        <TabItem isSelected={selectedTab === menu.value}>
          <Link to={`/?tab=${menu.value}`}>{menu.label}</Link>
        </TabItem>
      ))}
    </TabContainer>
  );
};

const TabContainer = styled.ul`
  display: flex;
  position: sticky;
  z-index: 4;
  background-color: white;
  top: 0;
  margin: 0;
  padding: 0;
`;

const TabItem = styled.li<{ isSelected: boolean }>`
  list-style-type: none;
  margin: 0;
  flex: 1;
  text-align: center;
  font-size: 15px;
  color: ${props => (props?.isSelected ? 'rgb(0, 0, 0)' : 'rgb(153, 161, 168)')};
  border-bottom: 2px solid
    ${props => (props?.isSelected ? 'rgb(33, 36, 42)' : 'rgb(240, 241, 244)')};
  cursor: pointer;

  a {
    all: unset;
    display: block;
    padding: 10px 0;
  }
`;
