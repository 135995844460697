import React, { FC } from 'react';
import styled from 'styled-components';

export const Select: FC = ({ children }) => (
  <SelectButton>
    {children}
    <svg
      viewBox="0 0 16 16"
      fill="rgb(117, 125, 134)"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
    >
      <path
        fill-rule="evenodd"
        d="M8.146 11.942a.213.213 0 0 1-.292 0L2.5 6.854 3.393 6l4.606 4.372L12.607 6l.893.854-5.354 5.088z"
      ></path>
    </svg>
  </SelectButton>
);

const SelectButton = styled.button`
  > svg {
    margin-left: 8px;
  }

  all: unset;
  padding: 8px 10px 7px 16px;
  color: rgb(19, 21, 24);
  font-size: 0.875rem;
  border: 1px solid rgb(201, 205, 210);
  border-radius: 4px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;
