import React, { FC, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Tab, Filters, Goods } from 'components';

const App: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedTab = searchParams.get('tab');

  useEffect(() => {
    if (selectedTab === null) {
      navigate('/?tab=goods');
    }
  }, [selectedTab])
  
  return (
    <>
      <Tab />
      <Filters />
      {selectedTab === 'goods' && <Goods />}
    </>
  );
};

export default App;
