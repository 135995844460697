import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Goods } from 'types/index';
import { badges } from 'constants/index';
import { numberWithComma, numberToK } from 'utils/lib';

interface IProps {
  goods: Goods;
}

export const Info: FC<IProps> = ({ goods }) => {
  const [isLiked, setIsLiked] = useState<boolean>(false);

  return (
    <InfoContainer>
      <div className="imageContainer">
        <img
          src={`https://usercontents-d.styleshare.io/images/${goods.picture.id}/384x384`}
          alt={`${goods.name}`}
        />
        <LikeButton isLiked={isLiked} onClick={() => setIsLiked(!isLiked)} />
      </div>
      <p>
        <strong className="brandname">{goods.brand.name}</strong>
        {goods.name}
      </p>
      <p className="priceInfo">
        {goods.discountRate !== 0 && (
          <strong className="discountRate">{goods.discountRate}% </strong>
        )}
        <strong className="goodsPrice">{numberWithComma(goods.price)}원</strong>
      </p>
      <div className="badgeList">
        {goods.badges.map(badge => (
          <div className="badge">{badges[badge]}</div>
        ))}
      </div>
      <p className="reviewCount">
        <span>좋아요 {numberToK(goods.likeCount)}</span>
        <span>리뷰 {numberToK(goods.reviewsCount)}</span>
      </p>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  font-size: 0.8125rem;

  .imageContainer {
    position: relative;
    margin-bottom: 8px;

    img {
      border-radius: 4px;
      width: 100%;
      filter: brightness(97%);
    }
  }

  p {
    margin: 0;
  }

  .brandname {
    margin-right: 5px;
    font-weight: 700;
  }

  .priceInfo {
    margin-top: 5px;
  }

  .discountRate {
    font-weight: 600;
    color: rgb(248, 21, 66);
  }

  .goodsPrice {
    font-weight: 600;
  }

  .badgeList {
    margin-top: 6px;
  }

  .badge {
    display: inline-block;
    padding: 3px 5px 4px;
    color: rgb(19, 21, 24);
    font-size: 11px;
    background-color: rgb(240, 241, 244);
    border-radius: 2px;

    &:not(:last-child) {
      margin-right: 3px;
    }
  }

  .reviewCount {
    color: rgb(117, 125, 134);
    font-size: 0.8125rem;
    line-height: 1rem;
    margin-top: 10px;

    span:first-child {
      margin-right: 8px;
    }
  }

  > img {
    border-radius: 4px;
  }
`;

const LikeButton = styled.button<{ isLiked: boolean }>`
  all: unset;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  border: 3px solid ${props => (props.isLiked ? 'rgb(248, 21, 66)' : 'white')};
  bottom: 8px;
  right: 5px;
  background-color: ${props => (props.isLiked ? 'rgb(248, 21, 66)' : 'none')};
`;
