import { Menu, Badges } from 'types/index';

export const menus: Menu[] = [
  { label: '전체', value: 'all' },
  { label: '상품', value: 'goods' },
  { label: '스타일', value: 'style' },
  { label: '사용자', value: 'users' },
];

export const filters: string[] = ['색상', '사이즈', '가격', '카테고리', '상품타입', '브랜드'];

export const badges: Badges = {
  only_styleshare: '단독',
  free_delivery: '무료배송',
};
