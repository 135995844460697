import React, { FC } from 'react';
import { Select } from './Select';
import { filters } from 'constants/index';
import styled from 'styled-components';

export const Filters: FC = () => (
  <FiltersContainer>
    {filters.map(filter => (
      <Select key={filter}>{filter}</Select>
    ))}
  </FiltersContainer>
);

const FiltersContainer = styled.div`
  margin: 16px 0 18px;
  padding: 0 10px;
  white-space: nowrap;
  overflow-x: scroll;
`;
