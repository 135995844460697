import axios from 'axios';
import { Goods } from 'types/index';

export const getGoodsList = async () =>
  await axios.get<{ data: Goods[] }>(
    'https://gist.githubusercontent.com/styleshare-frontend/811f0598ad8b8f27d34d93a6550f47a8/raw/131caf0b9142b5cdfb1d7989615356070fba7b91/goods-response.json',
    {
      headers: { 'Content-Type': 'text/plain' },
    },
  );
