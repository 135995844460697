import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Goods as TGoods } from 'types/index';
import { getGoodsList } from 'lib/api';
import { Info } from './Info';
import { breakpoint } from 'styles/breakpoint';

export const Goods: FC = () => {
  const [goodsList, setGoodsList] = useState<TGoods[]>([]);
  const fetchGoodsList = async () => {
    try {
      const response = await getGoodsList();
      setGoodsList(response.data.data);
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    fetchGoodsList();
  }, []);

  return (
    <GoodsContainer>
      {goodsList.map(goods => (
        <Info goods={goods} key={goods.id} />
      ))}
    </GoodsContainer>
  );
};

const GoodsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 12px;
  margin: 0 12px 80px;

  @media (min-width: ${breakpoint.desktop}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
